import axios, { AxiosRequestConfig } from 'axios';
import { IDataFetch } from './types';
import { API_URL, APP_DEV } from '@/constant/config'

export async function serviceFetch<Request, Response>({ path, method, config, data, params }: IDataFetch<Request>): Promise<Response> {
  let apiUrl: string
  apiUrl = !APP_DEV
  ? new URL('/api' + path, API_URL as string).toString()
  : new URL(path, API_URL as string).toString()
  if(params)
    apiUrl = apiUrl + params

  const axiosRequestConfig: AxiosRequestConfig = {
    ...config,
    url: apiUrl,
    method: method,
    data: data,
    withCredentials: true,
  };
  const res = await axios.request(axiosRequestConfig);
  return res.data;
}
